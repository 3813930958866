import React from "react";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';

const lineProps = (lineNumber) => {
	let style = { display: 'block' };
	if (lineNumber === 2) {
	  style.backgroundColor = '#ffdd57';
	}
	return { style };
  };
  
const codeString = `
function orderPizza(toppings, callback) {
  console.log("Haciendo tu pizza con los siguientes ingredientes:", toppings);
  setTimeout(() => {
      // Simula el tiempo que tarda en hacer la pizza
      console.log("Tu pizza está lista");
      callback(); // Llama al callback cuando la pizza esté lista
  }, 2000);
}

function pizzaReady() {
  console.log("Te hemos llamado para decirte que tu pizza está lista. ¡Ven a recogerla!");
}

// Hacer el pedido y pasar la función callback
orderPizza(["queso", "pepperoni"], pizzaReady);
  `;

const codeString2 = `
  function orderPizza(toppings) {
    console.log("Haciendo tu pizza con los siguientes ingredientes:", toppings);
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            // Simula el tiempo que tarda en hacer la pizza
            const success = true; // Cambia esto a false para simular un error
            if (success) {
                resolve("Tu pizza está lista. ¡Ven a recogerla!");
            } else {
                reject("Lo sentimos, no podemos hacer tu pizza.");
            }
        }, 2000);
    });
}

// Hacer el pedido y manejar la promesa
orderPizza(["queso", "pepperoni"])
    .then((message) => {
        console.log(message); // La promesa fue cumplida
    })
    .catch((error) => {
        console.log(error); // La promesa fue rechazada
    });

  `;

function article_1() {
	return {
		date: "5 Jul 2024",
		title: "Callbacks and Promises in Node.js: Explained with a Pizzeria",
		description:
			"How callbacks and promises work in Node.js through a simple and fun analogy: the operation of a pizzeria.",
		keywords: [
			"Callback and promises in Node.js",
			"Kevin",
			"Kevin M",
			"Kevin Miranda",
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<p>First time somebody explained me how callbacks and promises work I was impressed. My teammate was who explainme this en my first job in a fun and easy way.</p>
					<h2>Callback</h2>
					<p>Imagine you call a pizzeria to order a pizza. You give them your phone number to call you when the pizza is ready. This is the callback: 
						your phone number that the pizzeria will use to call you when the pizza is ready.</p>

					<SyntaxHighlighter language="javascript" wrapLines style={solarizedlight} lineProps={lineProps}>
						{codeString}
					</SyntaxHighlighter>

					<h2>Promise</h2>

					<p>A promise is an object representing a value that may be available now, in the future or never. 
						Promises allow to handle asynchronous operations in a more readable and structured way.</p>

					<p>
						Imagine you call the pizza place and they give you a promise that the pizza will be ready in 2 hours.This promise can be kept &#40;the pizza is ready&#41; or refused &#40;something went wrong and the pizza can't be made&#41;.
					</p>

					<SyntaxHighlighter language="javascript" wrapLines style={solarizedlight} lineProps={lineProps}>
						{codeString2}
					</SyntaxHighlighter>
				</div>


			</React.Fragment>
		),
	};
}

// function article_2() {
// 	return {
// 		date: "7 May 2023",
// 		title: "Min-Hashing",
// 		description:
// 			"AI is transforming the healthcare industry, from improving patient outcomes to streamlining operations. Discover the latest applications of this game-changing technology.",
// 		style: ``,
// 		keywords: [
// 			"Artificial Intelligence in Healthcare",
// 			"Kevin",
// 			"Kevin M",
// 			"Kevin Miranda",
// 		],
// 		body: (
// 			<React.Fragment>
// 				<h1>Content of article 2</h1>
// 			</React.Fragment>
// 		),
// 	};
// }

const myArticles = [article_1]//, article_2];

export default myArticles;
