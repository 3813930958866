const courses = [
  {
    title: 'AWS Certified Cloud Practitioner CLF-C02',
    number: '',
    link: 'https://www.udemy.com/course/aws-certified-cloud-practitioner-new/?couponCode=KEEPLEARNING',
    university: '',
  },
  {
    title: 'AWS Certified Solutions Architect Associate SAA-C03',
    number: '',
    link: 'https://www.udemy.com/course/aws-certified-solutions-architect-associate-saa-c03/',
    university: '',
  },
  // {
  //   title: 'Convolutional Neural Networks for Visual Recognition',
  //   number: 'CS 231n',
  //   link: 'http://cs231n.stanford.edu/',
  //   university: 'Stanford',
  // },
  // {
  //   title: 'Numerical Linear Algebra',
  //   number: 'CME 302',
  //   link:
  //     'http://scpd.stanford.edu/search/publicCourseSearchDetails.do;jsessionid=561188A06434D7D97953C4706DE12831?method=load&courseId=11685',
  //   university: 'Stanford',
  // },
  // {
  //   title: 'Numerical Optimization',
  //   number: 'CME 304',
  //   link: 'http://web.stanford.edu/class/cme304/',
  //   university: 'Stanford',
  // },
  // {
  //   title: 'Discrete Mathematics and Algorithms',
  //   number: 'CME 305',
  //   link: 'http://stanford.edu/~rezab/discrete/',
  //   university: 'Stanford',
  // },
  // {
  //   title: 'PDE’s of Applied Mathematics',
  //   number: 'CME 303',
  //   link: 'http://web.stanford.edu/class/math220/index.html',
  //   university: 'Stanford',
  // },
  // {
  //   title: 'Introduction to Linear Dynamical Systems',
  //   number: 'EE 263',
  //   link: 'http://ee263.stanford.edu/',
  //   university: 'Stanford',
  // },
  // {
  //   title: 'Introduction to Statistical Signal Processing',
  //   number: 'EE 278B',
  //   link: 'http://web.stanford.edu/class/ee278/',
  //   university: 'Stanford',
  // },
  // {
  //   title: 'Spacecraft Design',
  //   number: 'AA 236A',
  //   link: '',
  //   university: 'Stanford',
  // },
  // {
  //   title: 'Advanced Programming',
  //   number: 'CME 212',
  //   link: '',
  //   university: 'Stanford',
  // },
  // {
  //   title: 'Design Theory and Methodology',
  //   number: 'MAE 397',
  //   link:
  //     'https://web.stanford.edu/group/designx_lab/cgi-bin/mainwiki/index.php/ME397_Design_Theory_%26_Methodology_Seminar',
  //   university: 'Stanford',
  // },
  // {
  //   title: 'Software Engineering Concepts',
  //   number: 'CSE 442',
  //   link: '',
  //   university: 'Buffalo',
  // },
  // {
  //   title: 'Hardware/Software Integrated System Design',
  //   number: 'CSE 453',
  //   link: '',
  //   university: 'Buffalo',
  // },
  // {
  //   title: 'Data Structures',
  //   number: 'CS 250',
  //   link: 'http://www.cse.buffalo.edu/~hungngo/classes/2013/Fall/250/',
  //   university: 'Buffalo',
  // },
  // {
  //   title: 'Introduction to Digital Signal Processing',
  //   number: 'EE 516',
  //   link: '',
  //   university: 'Buffalo',
  // },
  // {
  //   title: 'Computer Vision and Image Processing',
  //   number: 'CSE 573',
  //   link: 'http://cubs.buffalo.edu/~inwogu/teaching/Coursepage573_fa14/',
  //   university: 'Buffalo',
  // },
  // {
  //   title: 'Realtime Embedded Systems',
  //   number: 'CSE 321',
  //   link: '',
  //   university: 'Buffalo',
  // },
  // {
  //   title: 'Computer Architecture',
  //   number: 'CSE 590',
  //   link: 'http://www.cse.buffalo.edu/~stevko/courses/cse490/spring11/',
  //   university: 'Buffalo',
  // },
  // {
  //   title: 'Small Data',
  //   number: 'MS&E 226',
  //   link: 'http://web.stanford.edu/class/msande226/',
  //   university: 'Stanford',
  // },
  // {
  //   title: 'Stochastic Control',
  //   number: 'EE 266',
  //   link: 'http://ee266.stanford.edu/',
  //   university: 'Stanford',
  // },
  // {
  //   title: 'Simulation',
  //   number: 'MS&E 223',
  //   link: 'http://web.stanford.edu/class/msande223/handout.htm',
  //   university: 'Stanford',
  // },
  // {
  //   title: 'Deep Learning for Natural Language Processing',
  //   number: 'CS 224d',
  //   link: 'http://cs224d.stanford.edu/',
  //   university: 'Stanford',
  // },
];

export default courses;
