import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import INFO from "../data/user";
import SEO from "../data/seo";

import Education from '../components/resume/Education';
import Experience from '../components/resume/Experience';
import Skills from '../components/resume/Skills';
import Courses from '../components/resume/Courses';
import References from '../components/resume/References';

import courses from '../data/resume/courses';
import degrees from '../data/resume/degrees';
import work from '../data/resume/work';
import { skills, categories } from '../data/resume/skills';

import "./styles/resume.css";

// NOTE: sections are displayed in order defined.
const sections = {
  Education: () => <Education data={degrees} />,
  Experience: () => <Experience data={work} />,
  Skills: () => <Skills skills={skills} categories={categories} />,
  Courses: () => <Courses data={courses} />,
  References: () => <References />,
};

const Resume = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "resume");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Resume | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="resume" />
				<div className="content-wrapper">
					<div className="resume-logo-container">
						<div className="resume-logo">
							<Logo width={46} />
						</div>
					</div>

					<article className="post" id="resume">
						<div className="resume-container">
						<div className="resume-main">
							<div className="resume-right-side">
								<div className="title resume-title">
									{INFO.resume.title}
								</div>

								<div className="link-container">
									{Object.keys(sections).map((sec) => (
									<h4 key={sec}>
										<a href={`#${sec.toLowerCase()}`}>{sec}</a>
									</h4>))}
								</div>
							</div>
						</div>
					</div>

					<div className="subtitle resume-subtitle">
						{INFO.resume.description}
					</div>


						{Object.entries(sections).map(([name, Section]) => (
							<Section key={name} />
						))}
					</article>

					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Resume;
