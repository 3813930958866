/**
 * @typedef {Object} Position
 * Conforms to https://jsonresume.org/schema/
 *
 * @property {string} name - Name of the company
 * @property {string} position - Position title
 * @property {string} url - Company website
 * @property {string} startDate - Start date of the position in YYYY-MM-DD format
 * @property {string|undefined} endDate - End date of the position in YYYY-MM-DD format.
 * If undefined, the position is still active.
 * @property {string|undefined} summary - html/markdown summary of the position
 * @property {string[]} highlights - plain text highlights of the position (bulleted list)
 */
const work = [
  {
    name: 'GBM',
    position: 'Software Engineer',
    url: 'https://gbm.com/',
    startDate: '2021-12-22',
    summary: `GBM is a leading company in the financial and stock market sector in Mexico. As a Software Engineer IV, my primary role involves leading projects from conception to implementation, 
    focusing on developing solutions for the Accounting department and its suppliers. I am part of a five-person team, where I develop and maintain critical systems.
    Currently, I am leading the migration of a project from AngularJS to NextJS (React), managing both the architecture and the complete development process.
    I dedicate approximately five hours daily to programming, using a variety of technologies and languages such as C#, SQL Server, Python, and JavaScript. 
    Additionally, I employ Windows tools and services like SSIS, SSRS, WCF, and IIS to ensure the functionality and efficiency of our applications. 
    The rest of my time is devoted to user communication, requirements analysis, and participation in strategic meetings to align the team's objectives with the company's goals.
    Among my notable achievements, I have successfully maintained legacy systems by migrating them to modern technologies, ensuring that GBM remains at the forefront of technology.`,
    highlights: [
      'Successfully re-engineered a legacy project from Angular.js (deprecated) to Next.js, enhancing performance, maintainability, and scalability.',
      'Reduced user errors in fiscal and accounting operations by 70% through improved system reliability and user interface enhancements.',
    ],
  },
  {
    name: 'DGAE [UNAM]',
    position: 'Full-stack Developer & QA ',
    url: 'https://www.dgae.unam.mx/',
    startDate: '2021-03-01',
    endDate: '2022-05-01',
    summary: `Skeptical Investments is a micro-vc fund focused on Angel, Seed, and Series-A
    YC companies.`,
    highlights: [
      'Developed and tested a school certificate signing platform using Laravel during the pandemic, which significantly increased the number of graduations from 2020 to 2021.',
      'Conducted QA testing to ensure system reliability and prevent failures.',
    ],
  },
  {
    name: 'IBM',
    position: 'Software Engineer (Intership)',
    url: 'https://www.ibm.com/mx-es',
    startDate: '2021-08-01',
    endDate: '2021-12-01',
    summary: `IBM, a global leader in technology and consulting services, provides a dynamic environment 
    for interns to engage with cutting-edge technologies and innovative projects. As an intern, I contributed to the development and documentation 
    of software services, gaining valuable hands-on experience in a professional setting.`,
    highlights: [
      'Authored comprehensive documentation for software services, ensuring clear and concise technical guidance for development teams.',
    ],
  },
  {
    name: 'Umvel',
    position: 'Backend Engineer',
    url: 'https://www.umvel.com/',
    startDate: '2019-02-01',
    endDate: '2021-02-01',
    summary: `Umvel is a software development consultancy focused on delivering innovative digital solutions.`,
    highlights: [
      'Developed multiple REST APIs using Node.js, enhancing system performance and reliability.',
      'Implemented caching solutions with Redis to improve application response times and reduce server load.',
      'Utilized Firebase for application authentication, extensively employing the OAuth 2.0 standard to ensure secure and efficient user authentication processes.',
      'Mentored and trained over 5 junior programmers and developers, fostering a collaborative and growth-oriented team environment.',
      'Successfully delivered up to 5 projects in the last two years, demonstrating strong project management and technical skills.'
    ],
  },
  {
    name: 'Develop for Good',
    position: 'Backend Developer (Volunteering)',
    url: 'https://www.developforgood.org/',
    startDate: '2021-07-01',
    endDate: '2021-012-01',
    highlights: [
      'Collaborated with a team to develop a nonprofit application for Care Somalia using PostgreSQL for database management and GraphQL for efficient data querying and manipulation.',
      'Contributed to the design and implementation of backend services, ensuring seamless data integration and robust performance.',
    ],
  },
];

export default work;
