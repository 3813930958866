const degrees = [
  {
    school: 'Universidad Nacional Autónoma de México',
    degree: 'Computer Science',
    link: 'https://www.fciencias.unam.mx/estudiar-en-ciencias/estudios/licenciaturas/ccomputacion',
    year: 2017,
  }
];

export default degrees;
