const INFO = {
	main: {
		title: "Portafolio",
		name: "Kevin Miranda",
		email: "krmirandas@gmail.com",
		logo: "../logok.png",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/krmirandas",
		linkedin: "https://www.linkedin.com/in/krmirandas/",
		instagram: "https://www.instagram.com/krmirandas/",
		stackoverflow: "https://stackoverflow.com/users/24814241/krmirandas",
		facebook: "https://www.facebook.com/krmirandas/",
	},

	homepage: {
		title: "Software Engineer, Cloud Developer, Dog Lover, and Amateur Artist",
		description:
		"I'm Kevin Miranda, a software engineer with experience in building scalable, secure, and reliable web applications using various frameworks and technologies. I have a strong interest in cloud development. I'm also a dog lover and have a German Shepherd, whom I enjoy walking with. I like traveling around Mexico and the world. Swimming is my favorite sport, and I am currently preparing for competitions. Occasionally, I also enjoy drawing cartoons."
	},

	about: {
		title: "I’m Kevin Miranda. I’m a software engineer born, raised, and based in Mexico",
		description:
			"I've worked on a variety of projects over the years and I'm proud of the progress I've made. Many of these projects are open-source and available for others to explore and contribute to. If you're interested in any of the projects I've worked on, please feel free to check out the code and suggest any improvements or enhancements you might have in mind. Collaborating with others is a great way to learn and grow, and I'm always open to new ideas and feedback.",
	},

	resume: {
		title: "Resume",
		description:
			"I’m an engineer with a strong interest in Cloud Development. I have 5+ years of experience with Javascript, Python and 1+ years using frameworks such as React.",
	},

	articles: {
		title: "I write to clarify what I think.",
		description:
			"Chronological collection of my long-form thoughts on programming, leadership, and more.",
	},

	projects: [
		{
			title: "Bunker Pet",
			description:
				"Bunker Pet was a school project that lasted for a month and consisted of a web application to find pet caregivers. Developed with Node.js, MongoDB, and Angular, it allowed users to rate and leave comments about caregivers. As a full-stack developer, I led the implementation and overcame challenges to create a functional and efficient platform.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/javascript/javascript.png",
			linkText: "View Project",
			link: "https://github.com/krmirandas/bunker-pet",
		},

		{
			title: "National Information and Market Integration System Scraper",
			description:
				"Basic Commodity Price Scraper based on the SNIIM webpage",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com/krmirandas/scraper-economia",
		},

		{
			title: "Coding-Problems",
			description:
				"I solve problems of Letcode, GeekForGeeks, HackerRank and other platforms.",
			logo: "https://pathrise-website-guide-wp.s3.us-west-1.amazonaws.com/guides/wp-content/uploads/2019/05/10175228/images-11.png",
			linkText: "View Project",
			link: "https://github.com/krmirandas/coding-problems",
		},

		{
			title: "Digital Image Processing",
			description:
				"Implementation of a series of filters applied to images.",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos/src/python/python.png",
			linkText: "View Project",
			link: "https://github.com",
		},

		{
			title: "Estructuras-de-Datos",
			description:
				"Implemented some of the best known data structures",
			logo: "https://cdn.jsdelivr.net/npm/programming-languages-logos@0.0.3/src/java/java.png",
			linkText: "View Project",
			link: "https://github.com/krmirandas/Estructuras-de-Datos",
		},
	],
};

export default INFO;
